<template>
  <ess-page-content :title="`${activeProfile.name} Groups`">
    <portal to="listHeader">
      <v-btn :block="isMobile"
             :disabled="$wait.any"
             color="success"
             large
             @click="createNewGroup">
        <v-icon left>
          mdi-plus
        </v-icon> Create Group
      </v-btn>
    </portal>

    <ess-simple-list :items="groups"
                     :loading="$wait.is('groupsLoading')"
                     title-icon="mdi-run"
                     empty-icon="mdi-run"
                     empty-icon-color="light-blue"
                     empty-message="No Groups"
                     empty-sub-message="Create a new Group and it will display here"
                     search-filter="groupName"
                     avatar
                     @item-click="selectGroup($event)">
      <template #item-content="slotData">
        <v-list-Item-content>
          <v-list-Item-title>{{ slotData.item.groupName }}</v-list-Item-title>
        </v-list-Item-content>
      </template>

      <template #item-action="slotData">
        <v-list-Item-action>
          <v-tooltip left>
            <template #activator="{on}">
              <v-list-Item-title v-on="on">
                <v-icon>mdi-run</v-icon>
                {{ slotData.item.groupMemberCount }}
              </v-list-Item-title>
            </template>
            <span>{{ slotData.item.groupMemberCount }} Members
            </span>
          </v-tooltip>
        </v-list-Item-action>
      </template>
    </ess-simple-list>
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import EssSimpleList from '@/components/core/EssSimpleList.vue';

export default {
  components: {
    EssSimpleList,
  },

  props: {},

  computed: {
    ...mapGetters({
      groups: 'groups/groups',
      activeProfile: 'profiles/activeProfile',
    }),
  },

  watch: {
    activeProfile() {
      this.getCoachGroups(this.authUser.email);
    },
  },

  created() {
    if (!this.$route.params.noLoad) {
      this.getCoachGroups(this.authUser.email);
    }
  },

  methods: {
    ...mapActions('groups', ['getCoachGroups', 'selectGroup', 'createNewGroup']),

    ...mapWaitingActions('groups', {
      getCoachGroups: 'groupsLoading',
    }),

  },
};
</script>
