<template>
  <ess-page-content :title="`${mode} Athlete`">
    <ValidationObserver ref="obs"
                        v-slot="{invalid, passes}">
      <ess-card title="Athlete Details">
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-text-field v-model="athlete.fullname"
                              :disabled="$wait.any"
                              label="Fullname"
                              rules="required" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-text-field v-model="athlete.email"
                              :disabled="mode === 'update' || $wait.any"
                              label="Email"
                              rules="required|email" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-select v-model="selectedGroups"
                          :items="groups"
                          :disabled="$wait.any"
                          :loading="$wait.is('groupsLoading')"
                          label="Athlete Groups"
                          item-text="groupName"
                          item-value="id"
                          multiple />
            </v-col>
          </v-row>

          <v-row>
            <v-card-title>
              <span class="text-truncate white--text">
                Body Composition
              </span>
            </v-card-title>
          </v-row>

          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-text-field v-model="athlete.bodyComposition.height"
                              :disabled="$wait.any"
                              label="Height"
                              rules="required" />
            </v-col>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-text-field v-model="athlete.bodyComposition.weight.current"
                              :disabled="$wait.any"
                              label="Weight"
                              rules="required" />
            </v-col>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-text-field v-model="athlete.bodyComposition.skinFold.current"
                              :disabled="$wait.any"
                              label="Skin Fold"
                              rules="required" />
            </v-col>
          </v-row>
        </v-card-text>

        <template slot="actions">
          <v-btn v-if="mode === 'update'"
                 :disabled="$wait.any"
                 color="error"
                 @click.native="dialogAction.isActive = true">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer />
          <v-btn text
                 @click="returnAthletes">
            Return
          </v-btn>
          <v-btn color="success"
                 :disabled="invalid"
                 :loading="$wait.is('saveAthleteLoading')"
                 @click="passes(save)">
            {{ mode | startCase }}
          </v-btn>
        </template>
      </ess-card>
    </ValidationObserver>

    <ess-dialog-notification v-if="dialogAction.isActive"
                             :title="dialogAction.title"
                             :content="dialogAction.content"
                             :alert="dialogAction.alert"
                             :button="dialogAction.button"
                             @cancelevent="dialogAction.isActive = false"
                             @action="deleteRecord" />
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationObserver } from 'vee-validate';

import EssDialogNotification from '@/components/core/EssDialogNotification.vue';

import User from '@/models/User';

export default {
  components: {
    ValidationObserver,
    EssDialogNotification,
  },

  props: {
    mode: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      athlete: new User(),
      selectedGroups: [],
      dialogAction: {
        currentAction: '',
        isActive: false,
        content: 'Are you sure you want to delete this athlete?',
        title: 'Delete Athlete',
        button: {
          ok: {
            text: 'Yes',
            class: ['error'],
          },
          cancel: {
            text: 'No',
          },
        },
        alert: {
          show: true,
          content: 'This action cannot be reversed!',
          type: 'error',
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      groups: 'groups/groups',
      athleteGroups: 'groups/athleteGroups',
    }),
  },

  watch: {
    athleteGroups(value) {
      this.selectedGroups = value;
    },
  },

  created() {
    this.getCoachGroups(this.authUser.email);

    if (this.mode === 'update') {
      this.athlete = new User(this.$store.getters['athletes/athlete']);
      this.getAthleteGroups(this.athlete.id);
    }
  },

  methods: {
    ...mapActions('athletes', ['createAthlete', 'updateAthlete', 'deleteAthlete']),
    ...mapActions('groups', ['getCoachGroups', 'getAthleteGroups']),
    // ...mapActions('session', ['resendAuthLink', 'killUserSession']),

    ...mapWaitingActions('groups', {
      getCoachGroups: 'groupsLoading',
    }),

    ...mapWaitingActions('athletes', {
      createAthlete: 'saveAthleteLoading',
      updateAthlete: 'saveAthleteLoading',
    }),

    async save() {
      try {
        const athleteData = {
          athlete: this.athlete,
          coachId: this.authUser.email,
          groupIds: this.selectedGroups,
          sendSigninLink: this.sendSigninLink,
        };

        await this[`${this.mode}Athlete`](athleteData);

        this.returnAthletes();
      } catch {
        // Do nothing
      }
    },

    returnAthletes() {
      const options = {
        name: 'CoachAthletes',
        params: {
          noLoad: true,
        },
      };

      this.$router.push(options);
    },

    deleteRecord() {
      this.deleteAthlete({
        athleteId: this.athlete.id,
        coachId: this.authUser.id,
      });

      this.returnAthletes();
    },
  },
};
</script>
