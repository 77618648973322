<template>
  <ess-page-content :title="`${activeProfile.name} Athlete Scores`">
    <ess-simple-list :items="athletes"
                     :loading="$wait.is('athletesLoading')"
                     title-icon="mdi-run"
                     empty-icon="mdi-run"
                     empty-icon-color="light-blue"
                     empty-message="No Athletes"
                     empty-sub-message="Create a new Athlete and it will display here"
                     search-filter="fullname"
                     avatar
                     @item-click="selectBenchmarkScoreAthlete($event)">
      <template #item-avatar="slotData">
        <v-list-item-avatar>
          <v-gravatar :email="slotData.item.email"
                      size.number="40"
                      default-img="mm" />
        </v-list-item-avatar>
      </template>

      <template #item-content="slotData">
        <v-list-item-content>
          <v-list-item-title>{{ slotData.item.fullname }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </ess-simple-list>
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import EssSimpleList from '@/components/core/EssSimpleList.vue';

export default {
  components: {
    EssSimpleList,
  },

  computed: {
    ...mapGetters({
      athletes: 'athletes/athletes',
      activeProfile: 'profiles/activeProfile',
    }),
  },

  watch: {
    activeProfile() {
      this.getCoachAthletes(this.authUser.email);
    },
  },

  created() {
    if (!this.$route.params.noLoad) {
      this.getCoachAthletes(this.authUser.email);
    }
  },

  methods: {
    ...mapActions('athletes', ['getCoachAthletes', 'selectBenchmarkScoreAthlete', 'createNewAthlete']),

    ...mapWaitingActions('athletes', {
      getCoachAthletes: 'athletesLoading',
    }),

  },
};
</script>
