<template>
  <v-card tile
          flat
          color="blue-grey darken-3">
    <v-card-title primary-title>
      How to set Goals
    </v-card-title>

    <v-card-text>
      <p class="text-body-1 mb-4 white--text">
        {{ help.heading }}
      </p>
      <template v-for="(item, index) in help.directions">
        <v-input :key="`direction-${index}`"
                 :prepend-icon="item.icon"
                 class="transparent">
          <span class="text-body-1">
            {{ item.description }}
          </span>
        </v-input>
      </template>
      <v-divider class="mt-1 mb-4" />
      <template v-for="(item, index) in help.options">
        <v-input :key="`option-${index}`"
                 :prepend-icon="item.icon"
                 class="transparent">
          <span class="text-body-1">
            {{ item.description }}
          </span>
        </v-input>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      help: {
        heading:
          'Goals are measured in different ways. A goal could be any of max weight, quickest time, longest distance or max reps. These can be customized to fit your requirement. Below are the different option icons explained when building your goal. Simply click the option button under "Goal Options" to cycle through the options.',
        directions: [
          {
            icon: 'mdi-trending-up',
            description:
              'Best score is calculated based on the highest number/time achieved.',
          },
          {
            icon: 'mdi-trending-down',
            description:
              'Best score is calculated based on the lowest number/time achieved.',
          },
        ],
        options: [
          {
            icon: 'mdi-weight-kilogram',
            description:
              'Weighted goals can either be based on a spcific weight, or calculated based on the athletes body weight by toggling the switch on the right.',
          },
          {
            icon: 'mdi-timer',
            description:
              'Timed goals are usually measured based on the shortest time it takes to complete a task, will be considered the best.',
          },
          {
            icon: 'mdi-all-inclusive',
            description:
              'Repititions could either be measured in how many repititions of a movement could be achieved in a timed fashion, or how many max unbroken repititions can be performed.',
          },
          {
            icon: 'mdi-ruler',
            description:
              'Measurement goals is based on distance achieved. This could be for example a max high jump, or max distance travelled.',
          },
        ],
      },
    };
  },
};
</script>
