<template>
  <ess-page-content :title="`${mode} Group`">
    <ValidationObserver ref="obs"
                        v-slot="{invalid, passes}">
      <ess-card title="Group Details">
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-text-field v-model="group.groupName"
                              :disabled="$wait.any"
                              label="Group Name"
                              rules="required" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12"
                   class="py-0">
              <ess-select v-model="selectedMembers"
                          :items="athletes"
                          :disabled="$wait.any"
                          :loading="$wait.is('athletesLoading')"
                          label="Group Members"
                          item-text="fullname"
                          item-value="id"
                          multiple />
            </v-col>
          </v-row>
        </v-card-text>

        <template slot="actions">
          <v-btn v-if="mode === 'update'"
                 :disabled="$wait.any"
                 color="error"
                 @click.native="dialogAction.isActive = true">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer />
          <v-btn text
                 @click="returnGroups">
            Return
          </v-btn>
          <v-btn color="success"
                 :disabled="invalid"
                 :loading="$wait.is('saveGroupLoading')"
                 @click="passes(save)">
            {{ mode | startCase }}
          </v-btn>
        </template>
      </ess-card>
    </ValidationObserver>

    <ess-dialog-notification v-if="dialogAction.isActive"
                             :title="dialogAction.title"
                             :content="dialogAction.content"
                             :alert="dialogAction.alert"
                             :button="dialogAction.button"
                             @cancelevent="dialogAction.isActive = false"
                             @action="deleteRecord" />
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationObserver } from 'vee-validate';

import EssDialogNotification from '@/components/core/EssDialogNotification.vue';

import Group from '@/models/Group';

export default {
  components: {
    ValidationObserver,
    EssDialogNotification,
  },

  props: {
    mode: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      group: new Group(),
      selectedMembers: [],
      dialogAction: {
        currentAction: '',
        isActive: false,
        content: 'Are you sure you want to delete this group?',
        title: 'Delete Group',
        button: {
          ok: {
            text: 'Yes',
            class: ['error'],
          },
          cancel: {
            text: 'No',
          },
        },
        alert: {
          show: true,
          content: 'This action cannot be reversed!',
          type: 'error',
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      athletes: 'athletes/athletes',
      groupMembers: 'groups/groupMembers',
    }),
  },

  watch: {
    groupMembers(value) {
      this.selectedMembers = value;
    },
  },

  created() {
    this.getCoachAthletes(this.authUser.email);

    if (this.mode === 'update') {
      this.group = new Group(this.$store.getters['groups/group']);
      this.getGroupMembers(this.group.id);
    }
  },

  methods: {
    ...mapActions('groups', ['getGroupMembers', 'createGroup', 'updateGroup', 'deleteGroup']),
    ...mapActions('athletes', ['getCoachAthletes']),

    ...mapWaitingActions('athletes', {
      getCoachAthletes: 'athletesLoading',
    }),

    ...mapWaitingActions('groups', {
      createGroup: 'saveGroupLoading',
      updateGroup: 'saveGroupLoading',
    }),

    async save() {
      const groupData = {
        group: this.group,
        coachId: this.authUser.email,
        athleteIds: this.selectedMembers,
      };

      await this[`${this.mode}Group`](groupData);

      this.returnGroups();
    },

    returnGroups() {
      const options = {
        name: 'CoachGroups',
        params: {
          noLoad: true,
        },
      };

      this.$router.push(options);
    },

    deleteRecord() {
      this.deleteGroup({
        groupId: this.group.id,
        coachId: this.authUser.id,
      });

      this.returnGroups();
    },
  },
};
</script>
