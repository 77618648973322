<template>
  <ess-page-content :title="`${mode} Goal`">
    <ValidationObserver ref="obs"
                        v-slot="{invalid, passes}">
      <ess-card title="Excercise Details">
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-text-field v-model="benchmark.benchmarkName"
                              :disabled="$wait.any"
                              label="Exercise Name"
                              rules="required" />
            </v-col>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <ess-select v-model="benchmark.type"
                          :items="types"
                          :disabled="$wait.any"
                          label="Type"
                          item-text="text"
                          item-value="value"
                          rules="selected|required" />
            </v-col>
          </v-row>

          <template v-if="mode === 'update'">
            <v-row>
              <v-card-title>
                <span class="text-truncate white--text">
                  Goals
                </span>
              </v-card-title>
            </v-row>

            <v-row>
              <!-- <v-col v-if="isMobile" -->
              <v-col cols="12">
                <v-card color="blue-grey darken-1"
                        flat>
                  <v-card-title>
                    Filter
                  </v-card-title>

                  <ess-select v-model="selectedGroups"
                              :items="groups"
                              :disabled="$wait.any"
                              :loading="$wait.is('groupsLoading')"
                              placeholder="Select Groups to Filter"
                              chips
                              deletable-chips
                              multiple
                              clearable
                              light
                              flat
                              hide-details
                              item-text="groupName"
                              item-value="id" />
                </v-card>
              </v-col>

              <!-- <v-col v-else
                     cols="3">
                <v-card color="blue-grey darken-1"
                        flat>
                  <v-card-title>
                    Filter by Group
                  </v-card-title>

                  <ess-group-list-select v-model="selectedGroups"
                                         :items="groups"
                                         :loading="$wait.is('groupsLoading')" />
                </v-card>
              </v-col> -->

              <v-col cols="12">
                <!-- md="9"> -->
                <v-card color="blue-grey darken-1"
                        flat>
                  <v-card-title>
                    <v-row>
                      <v-col cols="6"
                             class="py-0">
                        Athletes
                      </v-col>
                      <v-col cols="6"
                             class="text-right py-0">
                        <div class="text-right">
                          <v-btn icon
                                 @click="showHelp = !showHelp">
                            <v-icon>mdi-help-circle</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-expand-transition>
                    <ess-benchmark-help v-show="showHelp" />
                  </v-expand-transition>

                  <ess-loader v-if="$wait.is('athleteBenchmarksLoading')" />

                  <template v-else>
                    <v-card light
                            tile
                            hover>
                      <v-card-title class="pb-0 mb-0">
                        <span class="text-body-1">
                          Goal Options
                        </span>
                      </v-card-title>
                      <v-card-text class="py-0">
                        <v-container class="mt-0 pt-0">
                          <v-row align="center">
                            <v-col cols="3"
                                   class="py-0">
                              <v-row>
                                <v-tooltip top>
                                  <template #activator="{on}">
                                    <v-btn :disabled="filteredAthleteBenchmarks.length === 0"
                                           text
                                           icon
                                           v-on="on"
                                           @click="toggleGoalType()">
                                      <v-icon>{{ globalGoal.typeIcon }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ globalGoal.type | startCase }} - Goal Type: Is the goal based on <br> Weight, Time or Max Unbroken Reps.</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template #activator="{on}">
                                    <v-btn :disabled="filteredAthleteBenchmarks.length === 0"
                                           text
                                           icon
                                           v-on="on"
                                           @click="toggleGoalDirection()">
                                      <v-icon>{{ globalGoal.directionIcon }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ globalGoal.direction | startCase }} - Goal Direction: Is the goal's best based on <br> the highest or lowest value captured by an athlete</span>
                                </v-tooltip>
                              </v-row>
                            </v-col>

                            <v-col cols="3"
                                   class="py-0">
                              <v-tooltip bottom>
                                <template #activator="{on}">
                                  <ess-text-field v-model="globalGoal.goal"
                                                  :mask="globalGoal.mask"
                                                  :disabled="filteredAthleteBenchmarks.length === 0"
                                                  :placeholder="globalGoal.description"
                                                  :suffix="globalGoal.suffix"
                                                  :type="globalGoal.fieldType"
                                                  dark
                                                  hide-details
                                                  return-masked-value
                                                  v-on="on" />
                                </template>
                                <span>ex: {{ globalGoal.description }} {{ globalGoal.suffix }}</span>
                              </v-tooltip>
                            </v-col>

                            <v-col cols="2"
                                   class="py-0">
                              <v-tooltip bottom>
                                <template #activator="{on}">
                                  <ess-numeric-field v-model="globalGoal.weeklyGoalPercentage"
                                                     :max="100"
                                                     suffix="%"
                                                     dark
                                                     float
                                                     hide-details
                                                     v-on="on" />
                                </template>
                                <span>Weekly Goal: Percentage goals need to move on a weekly basis.</span>
                              </v-tooltip>
                            </v-col>

                            <v-col cols="2" />

                            <v-col cols="1">
                              <v-tooltip bottom>
                                <template #activator="{on}">
                                  <v-btn icon
                                         v-on="on"
                                         @click="selectAllBenchmarks = !selectAllBenchmarks">
                                    <v-icon>{{ getCheckIcon(selectAllBenchmarks) }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>Reset all athlete's base weekly goal</span>
                              </v-tooltip>
                            </v-col>

                            <v-col cols="1"
                                   class="py-0">
                              <v-row justify="center">
                                <v-tooltip v-if="globalGoal.type === GOALS.TYPES.WEIGHT"
                                           top>
                                  <template #activator="{on}">
                                    <v-switch v-model="globalGoal.isMultiplied"
                                              :disabled="filteredAthleteBenchmarks.length === 0"
                                              hide-details
                                              color="accent"
                                              class="mt-0 pt-0"
                                              v-on="on" />
                                  </template>
                                  <span>Multiply by Body Weight</span>
                                </v-tooltip>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>

                    <div v-if="filteredAthleteBenchmarks.length === 0"
                         class="pa-5 text-subtitle-1 text-center">
                      Nothing to display. Try adjusting your selected groups
                    </div>

                    <v-list v-else
                            class="transparent"
                            two-line>
                      <template v-for="(item, index) in filteredAthleteBenchmarks">
                        <v-divider v-if="index !== 0 && index !== filteredAthleteBenchmarks.length"
                                   :key="index" />
                        <v-list-item :key="`goal-${index}`"
                                     :disabled="$wait.any">
                          <v-list-item-content>
                            <v-container ma-0
                                         pa-0>
                              <v-row row
                                     wrap
                                     align="center">
                                <v-col cols="3">
                                  <v-list-item-title>{{ item.athlete.fullname }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ item.athlete.weight.current }} kg</v-list-item-subtitle>
                                </v-col>

                                <v-col cols="3">
                                  <v-tooltip bottom>
                                    <template #activator="{on}">
                                      <ess-text-field v-model="item.goal"
                                                      :mask="item.mask"
                                                      :placeholder="item.description"
                                                      :suffix="item.isMultiplied ? `x ${item.athlete.weight.current} kg = ${Math.round(item.goal * item.athlete.weight.current)} kg` : item.suffix"
                                                      :type="item.fieldType"
                                                      hide-details
                                                      return-masked-value
                                                      v-on="on" />
                                    </template>
                                    <span>ex: {{ item.description }} {{ item.suffix }}</span>
                                  </v-tooltip>
                                </v-col>

                                <v-col cols="2"
                                       class="py-0">
                                  <v-tooltip bottom>
                                    <template #activator="{on}">
                                      <ess-numeric-field v-model="item.weeklyGoalPercentage"
                                                         :max="100"
                                                         suffix="%"
                                                         dark
                                                         float
                                                         hide-details
                                                         v-on="on" />
                                    </template>
                                    <span>Weekly Goal: Percentage goals need to move on a weekly basis.</span>
                                  </v-tooltip>
                                </v-col>

                                <v-col cols="2">
                                  <v-tooltip bottom>
                                    <template #activator="{on}">
                                      <ess-text-field v-model="item.weeklyGoalBase"
                                                      :mask="item.mask"
                                                      :placeholder="item.description"
                                                      :suffix="`/ ${item.recent}`"
                                                      :type="item.fieldType"
                                                      hide-details
                                                      return-masked-value
                                                      v-on="on" />
                                    </template>
                                    <span>Weekly Goal: Base value on which the weekly goal is calculated.</span>
                                  </v-tooltip>
                                </v-col>

                                <v-col cols="1">
                                  <v-tooltip bottom>
                                    <template #activator="{on}">
                                      <v-btn icon
                                             v-on="on"
                                             @click="item.selected = !item.selected">
                                        <v-icon>{{ getCheckIcon(item.selected) }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Reset athlete's base weekly goal</span>
                                  </v-tooltip>
                                </v-col>

                                <v-col cols="1">
                                  <v-row justify="center">
                                    <v-tooltip v-if="globalGoal.type === GOALS.TYPES.WEIGHT"
                                               top>
                                      <template #activator="{on}">
                                        <v-switch v-model="item.isMultiplied"
                                                  hide-details
                                                  class="mt-0 pt-0"
                                                  v-on="on" />
                                      </template>
                                      <span>Multiply by Body Weight</span>
                                    </v-tooltip>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <template slot="actions">
          <v-btn v-if="mode === 'update'"
                 :disabled="$wait.any"
                 color="error"
                 @click.native="dialogAction.isActive = true">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <!-- <v-btn color="info"
                 :disabled="invalid"
                 @click="progressWeek">
            Progress Week
          </v-btn> -->

          <v-spacer />
          <v-btn text
                 @click="returnBenchmarks">
            Return
          </v-btn>
          <v-btn color="success"
                 :disabled="invalid || $wait.any"
                 :loading="$wait.is('saveBenchmarkLoading')"
                 @click="passes(save)">
            {{ mode | startCase }}
          </v-btn>
        </template>
      </ess-card>
    </ValidationObserver>

    <ess-dialog-notification v-if="dialogAction.isActive"
                             :title="dialogAction.title"
                             :content="dialogAction.content"
                             :alert="dialogAction.alert"
                             :button="dialogAction.button"
                             @cancelevent="dialogAction.isActive = false"
                             @action="deleteRecord" />
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationObserver } from 'vee-validate';
import _cloneDeep from 'lodash/cloneDeep';

import EssDialogNotification from '@/components/core/EssDialogNotification.vue';
// import EssGroupListSelect from '@/components/groups/EssGroupListSelect.vue';
import EssBenchmarkHelp from '@/components/help/EssBenchmarkHelp.vue';

import Benchmark from '@/models/Benchmark';

import { updateStoreArrayItem } from '@/utils/storeUtils';
import { GOALS } from '../../config/constants';
import { DEFAULT_GOAL } from '../../config/defaults';
import { calculateWeeklyGoal } from '../../utils';

export default {
  components: {
    ValidationObserver,
    EssDialogNotification,
    // EssGroupListSelect,
    EssBenchmarkHelp,
  },

  props: {
    mode: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      GOALS,
      benchmark: new Benchmark(),
      types: [
        { text: 'Gym', value: 'GYM' },
        { text: 'Fitness', value: 'FITNESS' },
      ],
      selectAllBenchmarks: false,
      selectedGroups: [],
      filteredAthleteBenchmarks: [],
      globalGoal: _cloneDeep(DEFAULT_GOAL.WEIGHT),
      dialogAction: {
        currentAction: '',
        isActive: false,
        content: 'Are you sure you want to delete this athlete?',
        title: 'Delete Athlete',
        button: {
          ok: {
            text: 'Yes',
            class: ['error'],
          },
          cancel: {
            text: 'No',
          },
        },
        alert: {
          show: true,
          content: 'This action cannot be reversed!',
          type: 'error',
        },
      },
      showHelp: false,
    };
  },

  computed: {
    ...mapGetters({
      groups: 'groups/groups',
      athleteBenchmarks: 'benchmarks/athleteBenchmarks',
    }),
  },

  watch: {
    selectAllBenchmarks(selected) {
      this.athleteBenchmarks.forEach((element, index) => {
        const athleteBenchmark = element;

        athleteBenchmark.selected = selected;

        this.athleteBenchmarks.splice(index, 1, athleteBenchmark);
      });
    },

    selectedGroups() {
      this.filterAthletes();
    },

    globalGoal: {
      handler() {
        let defaultGoal;

        if (this.globalGoal.type === GOALS.TYPES.WEIGHT && this.globalGoal.isMultiplied) {
          defaultGoal = _cloneDeep(DEFAULT_GOAL[`${this.globalGoal.type}_MULTIPLIED`]);
        } else {
          defaultGoal = _cloneDeep(DEFAULT_GOAL[`${this.globalGoal.type}`]);
        }

        this.globalGoal.mask = defaultGoal.mask;
        this.globalGoal.description = defaultGoal.description;
        this.globalGoal.suffix = defaultGoal.suffix;
        this.globalGoal.fieldType = defaultGoal.fieldType;

        this.filteredAthleteBenchmarks.forEach((filteredAthleteBenchmark) => {
          const athleteBenchmark = _cloneDeep(filteredAthleteBenchmark);
          athleteBenchmark.goal = this.globalGoal.goal;
          athleteBenchmark.goalType = this.globalGoal.type;
          athleteBenchmark.goalTypeIcon = GOALS.TYPE_ICONS[athleteBenchmark.goalType];
          athleteBenchmark.goalDirection = this.globalGoal.direction;
          athleteBenchmark.goalDirectionIcon = GOALS.DIRECTION_ICONS[athleteBenchmark.goalDirection];
          athleteBenchmark.weeklyGoalPercentage = this.globalGoal.weeklyGoalPercentage;
          athleteBenchmark.weeklyGoal = '';
          athleteBenchmark.isMultiplied = this.globalGoal.isMultiplied;
          athleteBenchmark.mask = this.globalGoal.mask;
          athleteBenchmark.description = this.globalGoal.description;
          athleteBenchmark.suffix = this.globalGoal.suffix;
          athleteBenchmark.fieldType = this.globalGoal.fieldType;

          athleteBenchmark.weeklyGoal = calculateWeeklyGoal(athleteBenchmark);

          updateStoreArrayItem('benchmarks/athleteBenchmarks', athleteBenchmark);
        });

        this.filterAthletes();
      },
      deep: true,
    },
  },

  async created() {
    if (this.mode === 'update') {
      this.benchmark = new Benchmark(this.$store.getters['benchmarks/benchmark']);

      const goals = Object.keys(GOALS.TYPES);
      const index = goals.indexOf(this.benchmark.goalType);

      this.globalGoal = this.getGoalType(index);
      this.globalGoal.direction = GOALS.DIRECTIONS[this.benchmark.goalDirection];
      this.globalGoal.directionIcon = GOALS.DIRECTION_ICONS[this.benchmark.goalDirection];
      this.globalGoal.isMultiplied = this.benchmark.goalIsMultiplied;
      this.globalGoal.goal = this.benchmark.goalValue;
      this.globalGoal.weeklyGoalPercentage = this.benchmark.weeklyGoalPercentage;

      this.getCoachGroups(this.authUser.email);

      await this.getAthleteBenchmarks({
        benchmarkId: this.benchmark.id,
        coachId: this.authUser.email,
      });

      // Default the base goal to recent captured value
      this.athleteBenchmarks.forEach((element) => {
        const athleteBenchmark = element;

        if (!athleteBenchmark.weeklyGoalBase) {
          athleteBenchmark.weeklyGoalBase = athleteBenchmark.recent;
        }
      });

      // Set the initial filter to show all athlete benchmarks
      this.filteredAthleteBenchmarks = this.athleteBenchmarks;
    }
  },

  methods: {
    ...mapActions('benchmarks', [
      'getAthleteBenchmarks',
      'createBenchmark',
      'updateBenchmark',
      'updateAthleteBenchmarkGoalsBulk',
      'deleteBenchmark',
      'progressWeek',
    ]),
    ...mapActions('groups', ['getCoachGroups']),

    ...mapWaitingActions('benchmarks', {
      createBenchmark: 'saveBenchmarkLoading',
      updateBenchmark: 'saveBenchmarkLoading',
      getAthleteBenchmarks: 'athleteBenchmarksLoading',
    }),

    ...mapWaitingActions('groups', {
      getCoachGroups: 'groupsLoading',
    }),

    async save() {
      this.benchmark.coachId = this.authUser.email;
      this.benchmark.goalType = this.globalGoal.type;
      this.benchmark.goalDirection = this.globalGoal.direction;
      this.benchmark.goalIsMultiplied = this.globalGoal.isMultiplied;
      this.benchmark.goalValue = this.globalGoal.goal;
      this.benchmark.weeklyGoalPercentage = this.globalGoal.weeklyGoalPercentage;

      const benchmarkData = {
        benchmark: this.benchmark,
      };

      await this[`${this.mode}Benchmark`](benchmarkData);

      if (this.mode === 'update') {
        await this.updateAthleteBenchmarkGoalsBulk({
          athleteBenchmarks: this.athleteBenchmarks,
        });
      }

      this.returnBenchmarks();
    },

    returnBenchmarks() {
      const options = {
        name: 'CoachBenchmarks',
        params: {
          noLoad: true,
        },
      };

      this.$router.push(options);
    },

    deleteRecord() {
      this.deleteBenchmark({
        benchmarkId: this.benchmark.id,
        coachId: this.authUser.id,
      });

      this.returnBenchmarks();
    },

    async filterAthletes() {
      // Ignore element 0 if its in null, meaning All was selected,
      // in which case there will be no group id
      if (this.selectedGroups[0] === null) {
        this.selectedGroups.splice(0, 1);
      }

      this.filteredAthleteBenchmarks = this.athleteBenchmarks.filter((x) => {
        const elementGroups = x.groups;
        return elementGroups.filter((group) => this.selectedGroups.indexOf(group) > -1).length === this.selectedGroups.length;
      });
    },

    getGoalType(index) {
      const goals = Object.keys(GOALS.TYPES);
      if (goals[index] === GOALS.TYPES.WEIGHT && this.globalGoal.isMultiplied) {
        return _cloneDeep(DEFAULT_GOAL[`${goals[index]}_MULTIPLIED`]);
      }
      return _cloneDeep(DEFAULT_GOAL[goals[index]]);
    },

    toggleGoalType() {
      const goals = Object.keys(GOALS.TYPES);
      let index = goals.indexOf(this.globalGoal.type);

      // Increment index, else reset to 0 if end of goals array
      index = index === goals.length - 1 ? 0 : (index += 1);

      this.globalGoal = this.getGoalType(index);
    },

    toggleGoalDirection() {
      if (this.globalGoal.direction === GOALS.DIRECTIONS.UP) {
        this.globalGoal.direction = GOALS.DIRECTIONS.DOWN;
        this.globalGoal.directionIcon = GOALS.DIRECTION_ICONS.DOWN;
      } else if (this.globalGoal.direction === GOALS.DIRECTIONS.DOWN) {
        this.globalGoal.direction = GOALS.DIRECTIONS.UP;
        this.globalGoal.directionIcon = GOALS.DIRECTION_ICONS.UP;
      }
    },

    getCheckIcon(checked) {
      return checked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline';
    },
  },
};
</script>
