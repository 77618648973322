<template>
  <ess-page-content :title="`${activeProfile.name} Athletes`">
    <portal to="listHeader">
      <v-btn :block="isMobile"
             :disabled="$wait.any"
             color="success"
             large
             @click="createNewAthlete">
        <v-icon left>
          mdi-plus
        </v-icon> Create Athlete
      </v-btn>
      <v-btn :block="isMobile"
             :disabled="$wait.any"
             color="secondary"
             style="margin-left: 8px"
             large
             @click="sendPasswordResetEmailToAllAthletes">
        Send reset email to all
      </v-btn>
    </portal>

    <ess-simple-list :items="athletes"
                     :loading="$wait.is('athletesLoading')"
                     title-icon="mdi-run"
                     empty-icon="mdi-run"
                     empty-icon-color="light-blue"
                     empty-message="No Athletes"
                     empty-sub-message="Create a new Athlete and it will display here"
                     search-filter="fullname"
                     avatar
                     @item-click="selectAthlete($event)">
      <template #item-avatar="slotData">
        <v-list-item-avatar>
          <v-gravatar :email="slotData.item.email"
                      size.number="40"
                      default-img="mm" />
        </v-list-item-avatar>
      </template>

      <template #item-content="slotData">
        <v-list-item-content>
          <v-list-item-title>{{ slotData.item.fullname }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <template #item-action="slotData">
        <v-list-item-action>
          <v-tooltip left>
            <template #activator="{on}">
              <v-chip v-on="on"
                      label
                      class="square-status"
                      :class="{'green white--text chip-shadow': slotData.item.isActive, 'red white--text chip-shadow': !slotData.item.isActive, 'orange white--text chip-shadow': !slotData.item.isVerified}" />
            </template>
            <span>
              Status: {{ !slotData.item.isVerified ? 'Account not Verified' : slotData.item.isActive ? 'Active' : 'Inactive' }}
            </span>
          </v-tooltip>
        </v-list-item-action>
      </template>
    </ess-simple-list>
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import EssSimpleList from '@/components/core/EssSimpleList.vue';

export default {
  components: {
    EssSimpleList,
  },

  props: {},

  computed: {
    ...mapGetters({
      athletes: 'athletes/athletes',
      activeProfile: 'profiles/activeProfile',
    }),
  },

  watch: {
    activeProfile() {
      this.getCoachAthletes(this.authUser.email);
    },
  },

  created() {
    if (!this.$route.params.noLoad) {
      this.getCoachAthletes(this.authUser.email);
    }
  },

  methods: {
    ...mapActions('athletes', ['getCoachAthletes', 'selectAthlete', 'createNewAthlete', 'sendPasswordResetEmailToAllAthletes']),

    ...mapWaitingActions('athletes', {
      getCoachAthletes: 'athletesLoading',
    }),
  },
};
</script>
