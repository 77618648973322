<template>
  <ess-page-content :title="`${activeProfile.name} Gym Goals`">
    <portal to="listHeader">
      <v-btn :block="isMobile"
             :disabled="$wait.any"
             color="success"
             large
             @click="createNewBenchmark">
        <v-icon left>
          mdi-plus
        </v-icon> Create Goal
      </v-btn>
    </portal>

    <ess-simple-list :items="gymBenchmarks"
                     :loading="$wait.is('benchmarksLoading')"
                     title-icon="mdi-run"
                     empty-icon="mdi-run"
                     empty-icon-color="light-blue"
                     empty-message="No Goal"
                     empty-sub-message="Create a new Goal and it will display here"
                     search-filter="benchmarkName"
                     avatar
                     @item-click="selectBenchmark($event)">
      <template #item-content="slotData">
        <v-list-Item-content>
          <v-list-Item-title>{{ slotData.item.benchmarkName }}</v-list-Item-title>
        </v-list-Item-content>
      </template>
    </ess-simple-list>

    <ess-title :title="`${activeProfile.name} Fitness Goals`" />

    <ess-simple-list :items="fitnessBenchmarks"
                     :loading="$wait.is('benchmarksLoading')"
                     title-icon="mdi-run"
                     empty-icon="mdi-run"
                     empty-icon-color="light-blue"
                     empty-message="No Goal"
                     empty-sub-message="Create a new Goal and it will display here"
                     search-filter="benchmarkName"
                     avatar
                     @item-click="selectBenchmark($event)">
      <template #item-content="slotData">
        <v-list-Item-content>
          <v-list-Item-title>{{ slotData.item.benchmarkName }}</v-list-Item-title>
        </v-list-Item-content>
      </template>
    </ess-simple-list>
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import EssSimpleList from '@/components/core/EssSimpleList.vue';

import { BENCHMARK_TYPES } from '../../config/constants';

export default {
  components: {
    EssSimpleList,
  },

  props: {},

  computed: {
    ...mapGetters({
      benchmarks: 'benchmarks/benchmarks',
      activeProfile: 'profiles/activeProfile',
    }),

    gymBenchmarks() {
      return this.benchmarks.filter((x) => x.type === BENCHMARK_TYPES.GYM);
    },

    fitnessBenchmarks() {
      return this.benchmarks.filter((x) => x.type === BENCHMARK_TYPES.FITNESS);
    },
  },

  watch: {
    activeProfile() {
      this.getCoachBenchmarks(this.authUser.email);
    },
  },

  created() {
    if (!this.$route.params.noLoad) {
      this.getCoachBenchmarks(this.authUser.email);
    }
  },

  methods: {
    ...mapActions('benchmarks', ['getCoachBenchmarks', 'selectBenchmark', 'createNewBenchmark']),

    ...mapWaitingActions('benchmarks', {
      getCoachBenchmarks: 'benchmarksLoading',
    }),
  },
};
</script>
